<div class="3sm:p-3 max-w-md">
  <h2 class="text-2xl text-center mt-0">{{ 'BEST_RESULTS_TRACKING' | transloco }}</h2>

  <em class="block mb-4 text-center">{{ 'BEST_RESULTS_TRACKING_INFO' | transloco }}</em>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let data of data.elitesByNationality">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="text-base flex items-center">
            <ng-container *ngIf="data[0].nationality !== 'ALL'; else worldIcon">
              <country-flag [country]="data[0].nationality" [size]="18"></country-flag>
            </ng-container>
            <ng-template #worldIcon><mat-icon>language</mat-icon></ng-template
            ><span class="inline-block ml-2">{{ 'COUNTRY_' + data[0].nationality | transloco }}</span>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <mat-radio-group [(ngModel)]="selectedValues[data[0].nationality]" class="flex flex-col gap-2 ml-[-2px]">
          <mat-radio-button [value]="0"
            ><span class="font-semibold">{{ 'NO_TRACKING' | transloco }}</span></mat-radio-button
          >
          <em class="text-xs">{{ 'TRACKED_RUNNERS_RESULTS' | transloco }}</em>
          <ng-container *ngFor="let elite of data">
            <mat-radio-button [value]="elite.id">
              <span class="font-semibold">
                Top {{ elite.results }} {{ elite.nationality !== 'ALL' ? elite.nationality : 'World' }}
              </span>
            </mat-radio-button>
            <em class="text-xs">
              {{ 'TOP_RESULTS_TRACKING_EXPLANATION' | transloco : { nationality: elite.nationality, level_m:
              elite.level_m / 100, level_w: elite.level_w / 100 } }}
            </em>
          </ng-container>
        </mat-radio-group>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="flex justify-between mt-6">
    <button mat-raised-button color="warn" [mat-dialog-close]="null">{{ 'CANCEL' | transloco }}</button>
    <button mat-raised-button color="accent" (click)="saveElitePreferences()">{{ 'SAVE' | transloco }}</button>
  </div>
</div>
