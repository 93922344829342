import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxsAuthState } from '@betrail-libs/auth-state';
import { IEliteLevel, INewsFeedGroup, IOtherResult, IResult, Trail } from '@betrail-libs/shared/interfaces/interfaces';
import { IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';
import { RankingOptions } from '@betrail-libs/shared/interfaces/ranking-options.model';
import { Sponsor } from '@betrail-libs/shared/interfaces/sponsor.model';

import { Race } from '@betrail-libs/shared/interfaces/race.model';
import { AnnouncementPopUpComponent } from '@betrail/ux/components/announcement-pop-up/announcement-pop-up.component';
import { BasicAlertDialogComponent } from '@betrail/ux/components/basic-alert-dialog/basic-alert-dialog.component';
import { BasicConfirmDialogComponent } from '@betrail/ux/components/basic-confirm-dialog/basic-confirm-dialog.component';
import { PopUpFormDialogComponent } from '../../admin/pages/popup-admin/components/pop-up-form-dialog/pop-up-form-dialog.component';
import { EditRankingDialogComponent } from '../../admin/pages/rankings-options/edit-ranking-dialog/edit-ranking-dialog.component';
import { EditSponsorDialogComponent } from '../../admin/pages/sponsor-admin/components/edit-sponsor-dialog/edit-sponsor-dialog.component';
import { CalendarFilterComponent } from '../../calendar/components/calendar-filter/calendar-filter.component';
import { AddRegistrationFormItemDialogComponent } from '../../organizer/components/add-registration-form-item-dialog/add-registration-form-item-dialog.component';
import { CancelRegistrationDialogComponent } from '../../organizer/components/cancel-registration-dialog/cancel-registration-dialog.component';
import { ConfirmCancelFormItemDialogComponent } from '../../organizer/components/confirm-cancel-form-item-dialog/confirm-cancel-form-item-dialog.component';
import { ConfirmDeleteFormItemDialogComponent } from '../../organizer/components/confirm-delete-form-item-dialog/confirm-delete-form-item-dialog.component';
import { EditRegistrationDialogComponent } from '../../organizer/components/edit-registration-dialog/edit-registration-dialog.component';
import { EditTranslationsDialogComponent } from '../../organizer/components/edit-translations-dialog/edit-translations-dialog.component';
import { ShowMessageToOrganizerDialogComponent } from '../../organizer/components/show-message-to-organizer-dialog/show-message-to-organizer-dialog.component';
import { OrganizerAccountDialogComponent } from '../../organizer/pages/organizer-account/organizer-account-dialog/organizer-account-dialog.component';
import { SelectOrganizerComponent } from '../../organizer/pages/select-organizer/select-organizer.component';
import { EditRaceDialogComponent } from '../../race/pages/components/edit-race-dialog/edit-race-dialog.component';
import { EditRaceDialogData } from '../../race/pages/components/edit-race-dialog/editRaceDialogData';
import { EditTrailDialogComponent } from '../../race/pages/components/edit-trail-dialog/edit-trail-dialog.component';
import { EditTrailDialogData } from '../../race/pages/components/edit-trail-dialog/editTrailDialogData';
import { RaceEventFromPredictedMsgComponent } from '../../race/pages/components/race-event-from-predicted-msg/race-event-from-predicted-msg.component';
import { RankingsNoSubDialogComponent } from '../../rankings-page/components/rankings-no-sub-dialog/rankings-no-sub-dialog.component';
import { ClaimResultFormComponent } from '../../runner/pages/runner-page/components/claim-result-form/claim-result-form.component';
import { DeleteOtherResultDialogComponent } from '../../runner/pages/runner-page/components/delete-other-result-dialog/delete-other-result-dialog.component';
import { EditResultFormComponent } from '../../runner/pages/runner-page/components/edit-result-form/edit-result-form.component';
import { RemoveResultFormComponent } from '../../runner/pages/runner-page/components/remove-result-form/remove-result-form.component';
import { ResultEditTagDialogComponent } from '../../runner/pages/runner-page/components/result-edit-tag-dialog/result-edit-tag-dialog.component';
import { ResultFilterData } from '../../runner/pages/runner-page/components/result-filter-dialog/ResultFilterData';
import { ResultFilterDialogComponent } from '../../runner/pages/runner-page/components/result-filter-dialog/result-filter-dialog.component';
import { AddResultDialogComponent } from '../../runner/pages/runner-page/components/result-other-add-dialog-form/result-add-other-result.component';
import { EditOtherResultDialogData } from '../../runner/pages/runner-page/components/result-other-edit-dialog-form/EditOtherResultDialogData';
import { EditResultDialogComponent } from '../../runner/pages/runner-page/components/result-other-edit-dialog-form/result-other-edit-dialog-form.component';
import { ResultSortDialog } from '../../runner/pages/runner-page/components/result-sort-dialog/result-sort-dialog.component';
import { RunnerElitesFollowDialogComponent } from '../../runner/pages/runner-page/components/runner-elites-follow-dialog/runner-elites-follow-dialog.component';
import { RunnerNewsFeedGroupDialogComponent } from '../../runner/pages/runner-page/components/runner-news-feed-group-dialog/runner-news-feed-group-dialog.component';
import { BasicPremiumDialogComponent } from '../../shared/basic-premium-dialog/basic-premium-dialog.component';
import { EditGeolocationDialogComponent } from '../../shared/edit-geolocation-dialog/edit-geolocation-dialog.component';
import { EditPathDialogComponent } from '../../shared/edit-path-dialog/edit-path-dialog.component';
import { EditRaceIndexDialogComponent } from '../../shared/edit-race-index-dialog/edit-race-index-dialog.component';
import { EditResultAdminDialogComponent } from '../../shared/edit-result-admin-dialog/edit-result-admin-dialog.component';
import { ErrorPopupComponent } from '../../shared/error-popup/error-popup.component';
import { PathDownloadWarningComponent } from '../../shared/path-download-warning/path-download-warning.component';
import { SearchRunnerDialogComponent } from '../../shared/search-runner-dialog/search-runner-dialog.component';
import { SponsorFormDialogComponent } from '../../shared/sponsor-form-dialog/sponsor-form-dialog.component';
import { EditTimerDialogComponent } from '../../timer/components/edit-timer-dialog/edit-timer-dialog.component';
import { EditTimerDialogData } from '../../timer/components/edit-timer-dialog/editTimerDialogData';
import { PremiumChangeSubscriptionDialogComponent } from '../../user-account/pages/premium-change-subscription-dialog/premium-change-subscription-dialog.component';
import { RaceSimulatorDetailsComponent } from './../../race/pages/components/race-simulator-details/race-simulator-details.component';

@Injectable({ providedIn: 'root', deps: [NgxsAuthState] })
export class DialogService {
  constructor(public dialog: MatDialog) {}

  defaultOptions: any = {
    width: '',
    height: '',
    maxWidth: '96%',
    maxHeight: '98%',
    panelClass: '',
    data: null,
  };

  showClaimResultDialog(result: IResult) {
    const options = { ...this.defaultOptions, maxWidth: '92%', data: { result } };
    const dialogRef: MatDialogRef<ClaimResultFormComponent> = this.dialog.open(ClaimResultFormComponent, options);
    return dialogRef.afterClosed();
  }

  showReportErrorDialog(result: IResult) {
    const options = { ...this.defaultOptions, maxWidth: '92%', data: { result } };
    const dialogRef: MatDialogRef<EditResultFormComponent> = this.dialog.open(EditResultFormComponent, options);
    return dialogRef.afterClosed();
  }

  showRejectResultDialog(result: IResult) {
    const options = { ...this.defaultOptions, maxWidth: '92%', data: { result, type: 'runner', id: result.runner.id } };
    const dialogRef: MatDialogRef<RemoveResultFormComponent> = this.dialog.open(RemoveResultFormComponent, options);
    return dialogRef.afterClosed();
  }

  showResultFilterDialog(data: ResultFilterData) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<ResultFilterDialogComponent> = this.dialog.open(ResultFilterDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showSortResultDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<ResultSortDialog> = this.dialog.open(ResultSortDialog, options);
    return dialogRef.afterClosed();
  }

  showEditRaceDialog(data: EditRaceDialogData) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<EditRaceDialogComponent> = this.dialog.open(EditRaceDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showCreateResultDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<AddResultDialogComponent> = this.dialog.open(AddResultDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showEditResultDialog(data: EditOtherResultDialogData) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<EditResultDialogComponent> = this.dialog.open(EditResultDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showDeleteResultDialog(data: any) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<DeleteOtherResultDialogComponent> = this.dialog.open(
      DeleteOtherResultDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showAdminEditResultDialog(result: IResult, eventId: number) {
    const options = this.defaultOptions;
    options.data = { result, eventId };
    const dialogRef: MatDialogRef<EditResultAdminDialogComponent> = this.dialog.open(
      EditResultAdminDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showBasicPremiumDialog(data: {
    message: { text: string; replacements?: unknown };
    buttons: { text: string; id: string }[];
  }) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<BasicPremiumDialogComponent> = this.dialog.open(BasicPremiumDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showEditTrailDialog(data: EditTrailDialogData) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<EditTrailDialogComponent> = this.dialog.open(EditTrailDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showEditTimerDialog(data: EditTimerDialogData) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = data;
    const dialogRef: MatDialogRef<EditTimerDialogComponent> = this.dialog.open(EditTimerDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showOrgaDialog(trail: Trail) {
    const options = this.defaultOptions;
    options.minHeight = '300px';
    options.data = trail;
    const dialogRef: MatDialogRef<OrganizerAccountDialogComponent> = this.dialog.open(
      OrganizerAccountDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showAddRegistrationFormItem(dialogData: any) {
    const options = this.defaultOptions;
    options.minHeight = '300px';
    options.data = dialogData;
    const dialogRef: MatDialogRef<AddRegistrationFormItemDialogComponent> = this.dialog.open(
      AddRegistrationFormItemDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showEditTranslationsDialog(translationKey: string) {
    const options = this.defaultOptions;
    options.minHeight = '300px';
    options.data = translationKey;
    const dialogRef: MatDialogRef<EditTranslationsDialogComponent> = this.dialog.open(
      EditTranslationsDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showConfirmCancelFormItemDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<ConfirmCancelFormItemDialogComponent> = this.dialog.open(
      ConfirmCancelFormItemDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showConfirmDeleteFormItemDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<ConfirmDeleteFormItemDialogComponent> = this.dialog.open(
      ConfirmDeleteFormItemDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showEditRegistration(registration: any) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = registration;
    const dialogRef: MatDialogRef<EditRegistrationDialogComponent> = this.dialog.open(
      EditRegistrationDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showEditPathDialog(path: any, type?: string, isNew?: boolean) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = { path, type, isNew };
    const dialogRef: MatDialogRef<EditPathDialogComponent> = this.dialog.open(EditPathDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showEditRaceIndexDialog(race: Race) {
    const options = this.defaultOptions;
    options.data = { race };
    const dialogRef: MatDialogRef<EditRaceIndexDialogComponent> = this.dialog.open(
      EditRaceIndexDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showCancelRegistration(registration: any) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = registration;
    const dialogRef: MatDialogRef<CancelRegistrationDialogComponent> = this.dialog.open(
      CancelRegistrationDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showMessageToOrganizer(message) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = message;
    const dialogRef: MatDialogRef<ShowMessageToOrganizerDialogComponent> = this.dialog.open(
      ShowMessageToOrganizerDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showCalendarFilter(data) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = data;
    const dialogRef: MatDialogRef<CalendarFilterComponent> = this.dialog.open(CalendarFilterComponent, options);
    return dialogRef.afterClosed();
  }

  showSimulatorDetails(simulation, type, estimationType = 'prediction') {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = { simulation, type, estimationType };
    const dialogRef: MatDialogRef<RaceSimulatorDetailsComponent> = this.dialog.open(
      RaceSimulatorDetailsComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showPathDownloadWarning(path) {
    const options = this.defaultOptions;
    options.width = '500px';
    options.minHeight = '300px';
    options.data = { path };
    const dialogRef: MatDialogRef<PathDownloadWarningComponent> = this.dialog.open(
      PathDownloadWarningComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showErrorMessage(message) {
    const options = this.defaultOptions;
    options.data = { message };
    const dialogRef: MatDialogRef<ErrorPopupComponent> = this.dialog.open(ErrorPopupComponent, options);
    return dialogRef.afterClosed();
  }

  showAlertDialog(title: string, message: string, messageSuppl = '', replacements?: unknown) {
    const options = this.defaultOptions;
    options.maxWidth = '500px';
    options.data = { title, message, messageSuppl, replacements };
    const dialogRef: MatDialogRef<BasicAlertDialogComponent> = this.dialog.open(BasicAlertDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showConfirmDialog(title: string, message: string, replacements?: unknown) {
    const options = this.defaultOptions;
    options.data = { title, message, replacements };
    const dialogRef: MatDialogRef<BasicConfirmDialogComponent> = this.dialog.open(BasicConfirmDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showSponsorFormDialog(role: string) {
    const options = this.defaultOptions;
    options.data = { role };
    const dialogRef: MatDialogRef<SponsorFormDialogComponent> = this.dialog.open(SponsorFormDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showEditSponsorDialog(sponsor: Sponsor) {
    const options = this.defaultOptions;
    options.data = { sponsor };
    const dialogRef: MatDialogRef<EditSponsorDialogComponent> = this.dialog.open(EditSponsorDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showEditRankingDialog(rankingOptions: RankingOptions) {
    const options = this.defaultOptions;
    options.data = rankingOptions;
    const dialogRef: MatDialogRef<EditRankingDialogComponent> = this.dialog.open(EditRankingDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showChangePremiumSubscriptionDialog(subscription: string) {
    const options = this.defaultOptions;
    options.data = { subscription };
    const dialogRef: MatDialogRef<PremiumChangeSubscriptionDialogComponent> = this.dialog.open(
      PremiumChangeSubscriptionDialogComponent,
      options,
    );

    return dialogRef.afterClosed();
  }

  showEditResultTagDialog(result: IResult | IOtherResult, isOtherResult: boolean) {
    const options = this.defaultOptions;
    options.data = { result, isOtherResult };
    const dialogRef: MatDialogRef<ResultEditTagDialogComponent> = this.dialog.open(
      ResultEditTagDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showPopUpFormDialog(popup?: IPopUpData) {
    const options = this.defaultOptions;
    options.data = { popup };
    const dialogRef: MatDialogRef<PopUpFormDialogComponent> = this.dialog.open(PopUpFormDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showAnnouncementPopUp(popup: IPopUpData, lang: 'Fr' | 'Nl' | 'En', userId?: number) {
    const options = this.defaultOptions;
    options.data = { popup, lang, userId };
    const dialogRef: MatDialogRef<AnnouncementPopUpComponent> = this.dialog.open(AnnouncementPopUpComponent, options);
    return dialogRef.afterClosed();
  }

  showSelectOrganizerDialog(organization: any) {
    const options = this.defaultOptions;
    options.data = { organization };
    const dialogRef: MatDialogRef<SelectOrganizerComponent> = this.dialog.open(SelectOrganizerComponent, options);
    return dialogRef.afterClosed();
  }

  showIsFromPredictedMsgDialog(alias: string, name: string, year: number) {
    const options = this.defaultOptions;
    options.data = { alias, name, year };
    const dialogRef: MatDialogRef<RaceEventFromPredictedMsgComponent> = this.dialog.open(
      RaceEventFromPredictedMsgComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showEditGeolocationDialog(trail: Trail) {
    const options = this.defaultOptions;
    options.data = { trail };
    const dialogRef: MatDialogRef<EditGeolocationDialogComponent> = this.dialog.open(
      EditGeolocationDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showFollowElitesDialog(elitesData: {
    elitesByNationality: IEliteLevel[][];
    userElites: { elid: number; nationality: string }[];
  }) {
    const options = this.defaultOptions;
    options.data = { ...elitesData };
    const dialogRef: MatDialogRef<RunnerElitesFollowDialogComponent> = this.dialog.open(
      RunnerElitesFollowDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showNewsFeedGroupDialog(uid: number, groups: INewsFeedGroup[]) {
    const options = this.defaultOptions;
    options.data = { uid, groups };
    const dialogRef: MatDialogRef<RunnerNewsFeedGroupDialogComponent> = this.dialog.open(
      RunnerNewsFeedGroupDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  showSearchRunnerDialog(data: {
    title: string;
    runnersToExclude?: number[];
    excludeMessage?: string;
    forTracking?: boolean;
  }) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<SearchRunnerDialogComponent> = this.dialog.open(SearchRunnerDialogComponent, options);
    return dialogRef.afterClosed();
  }

  showRankingNoSubDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<RankingsNoSubDialogComponent> = this.dialog.open(
      RankingsNoSubDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }
}
