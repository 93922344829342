import {
  INewsFeedGroup,
  INewsFeedParams,
  ITrackedRunner,
  ITrackingUser,
} from '@betrail-libs/shared/interfaces/interfaces';

const apiTag = '[Tracking API] ';

export class SetUserData {
  public static readonly type = apiTag + 'Set user data';
  constructor(
    public uid: number,
    public ruid: number,
    public isPremium: boolean,
    public trackedRunners: ITrackedRunner[],
    public trackedElites: { elid: number; nationality: string }[],
    public trackingGroups: INewsFeedGroup[],
    public userTrackings: ITrackingUser[],
  ) {}
}

export class TrackRunner {
  public static readonly type = apiTag + 'Track runner';
  constructor(public data: { uid: number; ruid: number; created_at: Date }) {}
}

export class UntrackRunner {
  public static readonly type = apiTag + 'Untrack runner';
  constructor(public data: { uid: number; ruid: number }) {}
}

export class LoadTrackedRunnerResults {
  public static readonly type = apiTag + 'Load tracked runner results';
  constructor(public nb = 5) {}
}

export class LoadEliteLevels {
  public static readonly type = apiTag + 'Load elite levels';
  constructor(public forceReload = false) {}
}

export class SetUserFollowedElites {
  public static readonly type = apiTag + 'Set user followed elites';
  constructor(public selected: { [nationality: string]: number }) {}
}

export class HandleTrackingGroupSettings {
  public static readonly type = apiTag + 'Handle tracking group settings';
  constructor(public uid: number, public data: INewsFeedGroup[]) {}
}

export class HandleGroupRunnerLink {
  public static readonly type = apiTag + 'Handle group runner link';
  constructor(public uid: number, public data: { activate: boolean; tgid: number; ruid: number }) {}
}

export class SetNewsFeedFilter {
  public static readonly type = apiTag + 'Set news feed filter';
  constructor(public filter?: INewsFeedParams) {}
}

export class ChangeTrackingOverride {
  public static readonly type = apiTag + 'Change tracking override';
  constructor(public data: { rtid: number; override: boolean }[]) {}
}
