<section class="flex flex-col max-w-sm">
  <header class="text-center">
    <h2 class="text-lg !mt-2">{{ 'GROUP_MANAGEMENT' | transloco }}</h2>
  </header>
  <hr />
  <div class="mx-2">
    <div>
      <h3>{{ 'ADD_NEW_GROUP' | transloco }}</h3>
      <div class="flex">
        <mat-form-field appearance="fill" color="accent" class="w-full">
          <mat-label>{{ 'GROUP_NAME' | transloco }}</mat-label>
          <input matInput [placeholder]="'GROUP_NAME_EXAMPLE' | transloco" [(ngModel)]="newGroupName" />
        </mat-form-field>
        <button
          mat-mini-fab
          color="accent"
          class="!ml-3 !mt-2"
          (click)="addGroup()"
          [disabled]="!newGroupName || newGroupName === ''"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <hr />
    <div>
      <h3>{{ 'MANAGING_YOUR_GROUPS' | transloco }}</h3>
      <em class="block mx-2 text-center">{{ 'GROUPE_ORDER_EXPLANATION' | transloco }}</em>
      <div
        *ngIf="sortedGroups.length > 0; else noGroup"
        cdkDropList
        class="cdk-drag-and-drop-container mt-3"
        (cdkDropListDropped)="changeGroupPriority($event)"
      >
        <div
          *ngFor="let group of sortedGroups; let i = index"
          cdkDragLockAxis="y"
          cdkDrag
          class="border bg-white text-black flex flex-col cursor-move"
        >
          <div class="custom-drop-placeholder" *cdkDragPlaceholder></div>
          <div class="w-full flex justify-between items-center">
            <span class="ml-4">{{ i + 1 }}. &nbsp; {{ group.name }} ({{ group.runners?.length || 0 }})</span>
            <button mat-icon-button color="warn" (click)="removeGroup(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <ng-template #noGroup>
        <div class="w-full mt-3 border bg-white text-black text-center py-3">
          <em>
            <ng-container *ngIf="data.groups.length === 0; else noMoreGroups">
              {{ 'NO_GROUP_CREATED' | transloco }}
            </ng-container>
            <ng-template #noMoreGroups>{{ 'NOTHING_TO_DISPLAY' | transloco }}</ng-template>
          </em>
        </div>
      </ng-template>
    </div>

    <div class="flex justify-between mt-8 mb-4">
      <button mat-raised-button color="warn" [mat-dialog-close]="null">{{ 'CANCEL' | transloco }}</button>
      <button mat-raised-button color="accent" (click)="saveModifications()" [disabled]="!hasChanges">
        {{ 'CONFIRM' | transloco }}
      </button>
    </div>
  </div>
</section>
