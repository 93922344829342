import { ERankingCode } from '@betrail-libs/shared/interfaces/interfaces';
import { RankingOptions } from '@betrail-libs/shared/interfaces/ranking-options.model';

const apiTag = '[Rankings API] ';

export class LoadRankingSummary {
  static readonly type = apiTag + 'Load Ranking Summary';
  constructor(
    public ranking: ERankingCode,
    public type?: string,
    public country?: string,
    public params?: { userId: number; groupId: number },
    public forceReload = false,
  ) {}
}

export class LoadRankings {
  static readonly type = apiTag + 'Load Rankings';
  constructor(
    public ranking: ERankingCode,
    public gender: 'female' | 'scratch',
    public type: string,
    public country?: string,
    public params?: { userId: number; groupId: number },
  ) {}
}

export class SetLoadingRanking {
  static readonly type = apiTag + 'Set Loading Ranking';
  constructor(
    public ranking: ERankingCode,
    public country: string,
    public gender?: 'female' | 'scratch' | 'all',
    public value = true,
  ) {}
}

export class SetRankingCountry {
  static readonly type = apiTag + 'Set Ranking Country';
  constructor(public ranking: ERankingCode, public country: string) {}
}

export class SetRankingOffset {
  static readonly type = apiTag + 'Set Ranking Offset';
  constructor(
    public ranking: ERankingCode,
    public country: string,
    public gender: 'female' | 'scratch',
    public type: string,
    public allOrGroupId: string,
    public offset: number,
  ) {}
}

export class SetGroupParams {
  static readonly type = apiTag + 'Set Group Params';
  constructor(public params?: { userId: number; groupId: number }) {}
}

export class LoadAllRankingsOptions {
  static readonly type = apiTag + 'Load All Rankings Options';
}

export class UpdateRankingOptions {
  static readonly type = apiTag + 'Update Ranking Options';
  constructor(public options: RankingOptions) {}
}

export class RemoveSponsorFromRanking {
  static readonly type = apiTag + 'Remove Sponsor from Ranking';
  constructor(public optionId: string | number) {}
}
