import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INewsFeedGroup } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-runner-news-feed-group-dialog',
  templateUrl: './runner-news-feed-group-dialog.component.html',
  styles: [
    `
      .cdk-drag-and-drop-container {
        .cdk-drag-preview {
          box-sizing: border-box;
          border-radius: 4px;
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }
        .cdk-drag-placeholder {
          opacity: 0.5;
        }
        .cdk-drag-animating {
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
        .drop-list.cdk-drop-list-dragging .drop-box:not(.cdk-drag-placeholder) {
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
        .custom-drop-placeholder {
          min-height: 46px;
          background: #ccc;
          border: dotted 2px #999;
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
      }
    `,
  ],
})
export class RunnerNewsFeedGroupDialogComponent {
  sortedGroups: INewsFeedGroup[] = [];
  hasChanges = false;
  newGroupName?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uid: number; groups: INewsFeedGroup[] },
    private dialogRef: MatDialogRef<RunnerNewsFeedGroupDialogComponent>,
  ) {
    this.sortedGroups = [...data.groups].sort((a, b) => a.priority - b.priority);
  }

  addGroup() {
    if (this.newGroupName) {
      this.hasChanges = true;
      this.sortedGroups.push({ id: 0, name: this.newGroupName.trim(), priority: this.sortedGroups.length + 1 });
      this.newGroupName = undefined;
    }
  }

  changeGroupPriority(event: CdkDragDrop<INewsFeedGroup[]>) {
    this.hasChanges = true;
    moveItemInArray(this.sortedGroups, event.previousIndex, event.currentIndex);
  }

  removeGroup(index: number) {
    this.hasChanges = true;
    this.sortedGroups.splice(index, 1);
    this.sortedGroups = this.sortedGroups.map((g, i) => ({ ...g, priority: i + 1 }));
  }

  saveModifications() {
    this.dialogRef.close(this.sortedGroups);
  }
}
