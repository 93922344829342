<div class="flex items-center my-2" [style.opacity]="runner.disabled ? '0.6' : '1'">
  <div
    class="mat-elevation-z1 w-10 h-10 rounded-[50%] bg-runner bg-cover bg-center border-solid border-2 text-white"
    [style.border-color]="runner.premium ? '#ff420f' : 'white'"
  >
    <ng-container *ngIf="runner.photo; else nameLetters">
      <img class="rounded-[50%] w-full h-full" [src]="runner.photo | sanitizeUrl" />
    </ng-container>
    <ng-template #nameLetters>
      <div class="h-full w-full flex justify-center items-center">
        <span class="text-xl font-semibold uppercase cursor-default">
          <ng-container *ngIf="runner.name; else anonymous">{{ runner.name | initialsFromTitle }}</ng-container>
          <ng-template #anonymous>??</ng-template>
        </span>
      </div>
    </ng-template>
    <div
      *ngIf="runner.premium"
      class="h-3 w-3 rounded-[50%] bg-premium flex justify-center items-center relative left-8 bottom-5"
    >
      <span class="text-[8px]">P</span>
    </div>
  </div>

  <div *ngIf="runner.name && runner.alias; else noRunner" class="flex flex-col ml-2 uppercase">
    <div class="inline-block">
      <a class="text-sm font-semibold underline" [routerLink]="['/', 'runner', runner.alias]">{{ runner.name }}</a>
      <country-flag [country]="runner.nationality" [size]="12" class="ml-1 mb-[2px]"></country-flag>
    </div>
    <div class="text-gray-500 text-xs h-4">
      <span>{{ runner.place }}</span>
      <ng-container *ngIf="runner.place && runner.age">,&nbsp;</ng-container>
      <span *ngIf="runner.age">{{ runner.age }} {{ 'RUNNER_YEARS_OLD' | transloco }}</span>
    </div>
  </div>
  <ng-template #noRunner>
    <em class="ml-1 text-sm">{{ 'RUNNER_NO_LONGER_EXISTS' | transloco }}</em>
  </ng-template>
</div>
