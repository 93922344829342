import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { MasqueradeComponent } from './masquerade.component';

@Injectable({ providedIn: 'root' })
export class MasqueradeService {
  constructor(public dialog: MatDialog, private authService: AuthStateService) {}

  openInputMasquerade() {
    const dialogRef = this.dialog.open(MasqueradeComponent, {
      width: '400px',
      height: '280px',
      data: { uid: undefined },
    });

    dialogRef.afterClosed().subscribe(result => {
      result && this.authService.masquerade(result);
    });
  }
}
